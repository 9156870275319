.padding {
    padding: 100px 5%;
    max-width: 940px;
    margin: 0 auto;
    position: relative;

    @media screen and (min-width: 900px) {
        padding: 100px 10%;
    }

    .divider-line {
        position: absolute;
        width: 120vw;
        left: -10%;
        right: 0;
        bottom: 0;
    }

    &.generic {
        margin: 0 auto;
        opacity: 0;
        transform: translateY(2vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        will-change: opacity, visibility;
        margin-bottom: 70px;

        @media screen and (min-width: 900px) {
            margin-bottom: 100px
        }

        @media screen and (min-width: 1300px) {
            margin-bottom: 140px;
        }
    
        &.fade-in {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    
        &.cta {
            padding-bottom: 100px;
            position: relative;
            @media screen and (min-width: 600px) {
                padding-bottom: 0;
            }
        }
    
        .sub-text {
            color: white;
            position: relative;
            z-index: 1;
        }
    }

    &.downloads {
        @media screen and (min-width: 900px) {
            padding: 58px 5% 20px 5%;
        }
    }

    .heading {
        border-bottom: 2px solid;
        padding-bottom: 12px;
    }
}

.divider-lol {
    background: url('../images/BluePhatLine.svg');
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100%;
    bottom: -70px;
    left: -100%;
    right: 0;
    bottom: -40px;
    width: 240%;
    height: 290px;

    @media screen and (min-width: 440px) {
        height: 500px;
        bottom: -190px;
        left: -100px;
    }

    @media screen and (min-width: 600px) {
        width: 130%;
        left: -130px;
        height: 450px;
    }

    @media screen and (min-width: 1000px) {
        width: 120%;
        height: 500px;
    }
    @media screen and (min-width: 1400px) {
        width: 130%;
        left: -200px;
        height: 580px;
    }

    @media screen and (min-width: 1500px) {
        width: 130%;
        left: -200px;
        height: 670px;
    }

    @media screen and (min-width: 1700px) {
        width: 130%;
        left: -200px;
        height: 750px;
    }
}