.video {
    @media screen and (min-width: 800px) {
        min-width: 600px;
        margin: 0 auto;
        position: relative;
        left: -20px;
        margin-top: 80px;
    }

    @media screen and (min-width: 900px) {
        max-width: 750px;
    }

    &.video-section {
        width: 100%;
        margin-top: 100px;
        padding: 0;
    }
}