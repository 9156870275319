.header-wrapper {
    position: relative;
    @media screen and (min-width: 1400px) {
        height: 100vh;
        overflow: hidden;   
    }
    header {
        @media screen and (min-width: 900px) {
            position: absolute;
            top: 5%;
            width: 100%;
            pointer-events: none;
        }

        @media screen and (min-width: 1200px) {
            top: 18%;
        }

        @media screen and (min-width: 1400px) {
            top: 50%;
            transform: translateY(-50%);
        }
    
        .menu {
            background: white;
        }
    
        .content {
            height: 70vh;
            min-height: 600px;
            background: blue;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: 900px) {
                background: transparent;
                height: auto;
                min-height: auto;
            }
    
            .hero-logo {
                max-width: 600px;
                width: 100%;

                @media screen and (min-width: 900px) {
                    max-width: 700px;
                }
            }
        
            .hero-sub {
                max-width: 260px;
                margin: 0 auto 40px;
                font-size: 19px;
                color: black;
                text-align: center;

                @media screen and (min-width: 900px) {
                    max-width: 410px;
                    font-size: 22px;
                }
            }
        }
    }
    .background {
        display: none;
        @media screen and (min-width: 900px) {
            display: block;
            position: relative;
            left: 0;
            width: 100%;
        }
    }
}