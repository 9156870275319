.donate-wrapper {
    margin-top: 30px;

    @media screen and (min-width: 900px) {
      display: flex;
      justify-content: space-between;
    }

    .sub-text {
      margin: 0 0 24px 0;
    }

    .sub-text, a {
      color: blue;
    }

    .paypal-wrapper {
      width: 220px;
    }
}