@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:500&display=swap');

h1 {
    margin-top: 0;
}

p, a {
    font-family: 'IBM Plex Mono';
}

.heading {
    font-family: 'IBM Plex Mono';
    font-size: 30px;
    letter-spacing: 1px;
    color: white;
    margin-top: 0;
    font-weight: lighter;
    margin: 0 auto 22px auto;

    @media screen and (min-width: 600px) {
        font-size: 36px;
    }
}

.sub-text {
    margin-top: 0;
    font-size: 18px;
    margin: 0 auto;
    line-height: 32px;
}
