.hamburger-icon {
    position: relative;
    padding-top: 24px;
    margin: 0 24px 0 auto;
    width: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        padding-top: 48px;
        margin: 0 48px 0 auto;
    }

    span {
        background: black;
        width: 30px;
        height: 3px;
        border-radius: 8px;

        &:nth-child(odd) {
            margin-bottom: 8px;
        }
    }
}

.menu {

}