.hero-button {
    pointer-events: all;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 7px;
    width: 160px;
    margin: 0 auto;
    text-align: center;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    &.donate {
        border-color: blue;
        margin: 0;

        &:nth-child(2) {
            margin: 24px 0;
        }

        a {
            color: blue;
        }
    }

    &.reviews {
        float: right;
        position: absolute;
        background: transparent;
        font-family: 'IBM Plex Mono';
        border: white solid 1px;
        color: white;
        letter-spacing: 1px;
        bottom: 100px;
        margin-top: 0;
        @media screen and (min-width: 600px) {
            position: relative;
            top: -80px;
            bottom: auto;
        }
    }

    &.footer-button {
        border-color: white;
        padding: 10px 20px;
        margin-left: 14px;
        margin-top: 24px;
        @media screen and (min-width: 600px) {
            margin-right: 10px;
            margin-left: 0;
            margin-top: 0;
        }
    }

    a {
        text-decoration: none;
        font-size: 12px;
        font-style: none;
        font-family: 'IBM Plex Mono';
        color: black;
    }

    .icon {
        width: 20px;
        margin-top: -3px;
    }
}

.show-more-button {
    pointer-events: all;
    border: 1px solid black;
    border-radius: 7px;
    padding: 10px 12px;
    width: 152px;
    margin: 30px auto 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media screen and (min-width: 600px) {
        display: none;
    }

    a {
        text-decoration: none;
        font-size: 12px;
        font-style: none;
        font-family: 'IBM Plex Mono';
        color: black;
    }

    .icon {
        width: 12px;
    }
}