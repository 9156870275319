.logo-wrapper {
    display: grid;
    align-items: center;
    grid-gap: 50px 10px;
    margin-top: 42px;
    overflow: hidden;
    max-height: 230px;

    &.show {
        max-height: none;
    }

    img {
        height: 40px;
        margin: 0 auto;
    }

    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 70px 10px;
        max-height: none;

        img {
            height: 50px;
            margin: 0;
        }
    }

    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }


    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr;

        img {
            height: 50px;
        }
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .slack {
        height: 80px;
    }
}