.footer {
    padding: 40px 24px;
    @media screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-logo {
        width: 190px;
    }
}