.counter {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;

    @media screen and (min-width: 900px) {
        margin-right: 52px; // width of one of the counters so that it look more center
    }

    .heading {
        text-align: center;
        font-size: 45px;
        letter-spacing: 4px;
        font-weight: lighter;
        border-bottom: none!important;

        @media screen and (min-width: 400px) {
            font-size: 60px;
            margin: 0;
        }

        @media screen and (min-width: 600px) {
            font-size: 90px;
        }

        @media screen and (min-width: 900px) {
            font-size: 120px;
        }
    }
    .counter-wrapper {
        position: relative;
        margin-top: 40px;

        @media screen and (min-width: 500px) {
            margin-top: 30px;
        }

        @media screen and (min-width: 800px) {
            top: -28px;
            right: -70px;
            margin-top: 0;
            text-align: right;
        }

        @media screen and (min-width: 900px) {
            padding: 22px 15px;
            top: -70px;
            font-size: 48px;
        }

        span {
            color: white;
            text-align: center;
            font-size: 32px;
            padding: 20px 12px;
            border: 2px solid white;
            border-radius: 40px;
            margin-right: 10px;
            font-family: 'IBM Plex Mono';

            @media screen and (min-width: 600px) {
                padding: 20px 15px;
                font-size: 48px;
            }

            @media screen and (min-width: 900px) {
                padding: 20px 10px;
                font-size: 60px;
            }
        }
    }
}